<template>
  <div class="mx-3 mt-4 mb-15">
    <!-- DESKTOP -->
    <div class="mb-xl-podcast" v-if="!isMobile">
      <v-row class="v-step-11">
        <v-col class="mt-10 pl-8 pr-8 text-left" cols="12">
          <h1 class="podcast__title">Pódcast</h1>
        </v-col>
      </v-row>      
      <v-row class="mt-10">
        
        
        <!-- <template v-for="data in listPodcast" v-if="data.cedulas.includes(userInfo.document_number) || data.perfil.includes(userInfo.profile.id)"> -->
        <template v-for="data in listPodcast">                    
          <v-col :key="data.id" class="pl-8 pr-8 mt-5" cols="12">
            <v-card elevation="0" style="background-color: #f0f8ff00">
              <v-row align="center">
                <v-col cols="12" md="3" xs="3" sm="3" lg="3" xl="3">
                  <v-img width="300" :src="data.imagen" class="podcast__image">
                  </v-img>
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  xs="8"
                  sm="8"
                  lg="8"
                  xl="8"
                  class="text-left"
                >
                  <p style="color: #5e5e5e">
                    {{ data.etiquetas }}
                  </p>
                  <!-- <p>{{ data.cedulas }}</p> -->
                  
                  <p class="text-title-xl">
                    {{ data.titulo }}
                  </p>
                  <div style="height: 100px; background-color: #f1f3f4">
                    <div v-show="!data.showAudio" class="pt-3">
                      <v-btn @click="open(data)" class="pl-3 pb-1" text>
                        <v-icon large> mdi-play </v-icon>
                        <p class="mb-0 pb-0 pt-1" style="font-size: 12px">
                          Reproducir
                        </p>
                      </v-btn>
                    </div>
                    <small class="pb-0 mb-0 pl-5" style="color: #5e5e5e">
                      {{ data.comentario }}
                    </small>
                  </div>
                  <p
                    v-html="data.descripcion"
                    style="color: #5e5e5e"
                    class="mt-6"
                  ></p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </div>

    <!-- MOBILE -->

    <div class="mb-sm-podcast" v-else>
      <v-row class="mt-4">
        <v-col style="text-align: left" cols="12" class="py-4 px-2 index-f-sm">
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1">
              <label style="padding-top: 5%" class="podcast__name-text txt-c">
                {{ name }},
              </label>
              <label style="padding-top: 5%" class="podcast__name-text">
                acá encontrará
              </label>
            </p>
          </div>
          <p style="text-align: left" class="podcast__name-subtext mb-1 ml-9">
            Pódcast de colaboradores
          </p>
        </v-col>
        <template v-for="data in listPodcast">
          <v-col :key="data.id" class="pl-8 pr-8 mt-0" cols="12">
            <v-card elevation="0" style="background-color: #f0f8ff00">
              <v-row align="center">
                <v-col cols="12" md="3" xs="3" sm="12" lg="3" xl="3">
                  <p class="text-left" style="color: #5e5e5e">
                     {{ data.etiquetas }}
                  </p>
                  <p class="text-title-xl">
                    {{ data.titulo }}
                  </p>
                  <div
                    class="d-flex justify-center podcast__container-img-mobile"
                  >
                    <v-img
                      width="300"
                      :src="data.imagen"
                      class="podcast__image"
                    >
                    </v-img>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  xs="8"
                  sm="12"
                  lg="8"
                  xl="8"
                  class="text-left"
                >
                  <div style="height: 120px; background-color: #f1f3f4">
                    <div v-show="!data.showAudio" class="pt-3">
                      <v-btn @click="open(data)" class="pl-3 pb-1" text>
                        <v-icon large> mdi-play </v-icon>
                        <p class="mb-0 pb-0 pt-1" style="font-size: 12px">
                          Reproducir
                        </p>
                      </v-btn>
                    </div>
                    <small class="pb-0 mb-0 pl-5" style="color: #5e5e5e">
                      {{ data.comentario }}
                    </small>
                  </div>
                  <p
                    v-html="data.descripcion"
                    style="color: #5e5e5e"
                    class="mt-6"
                  ></p>
                </v-col>
              </v-row>
            </v-card>
            <v-divider class="mt-6"></v-divider>
          </v-col>
        </template>
      </v-row>
    </div>

    <v-dialog
      max-width="400"
      v-model="modal.open"
      content-class="podcast__dialog"
    >
      <v-layout justify-center>
        <v-btn
          color="#466be3"
          class="mb-2 podcast__button"
          dark
          small
          @click="modal.open = false"
        >
          cerrar
        </v-btn>
      </v-layout>
      <v-card elevation="0">
        <v-img width="400" :src="audioInfo.img"> </v-img>
        <div id="container-audio">
          <!-- {{audioInfo.url}} -->
          <audio
            autoplay
            id="audio-e"
            ref="audio1"
            controls
            class="mt-1"
            style="width: 100%"
          >
            <source id="source-id" :src="audioInfo.url" type="audio/mpeg" />
            <object
              type="application/x-shockwave-flash"
              data="player.swf?miArchivo=mi-archivo-de-audio.mp3"
            >
              <param
                name="movie"
                value="player.swf?miArchivo=mi-archivo-de-audio.mp3"
              />
            </object>
          </audio>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="modal.video">
      <v-card elevation="0" style="background-color: #f0f8ff00">
        <v-layout justify-center>
          <v-btn color="#466be3" class="mb-2" dark small @click="modal.video = false">
            <strong> cerrar </strong>
          </v-btn>
        </v-layout>
        <video-player
          :height="220"
          class="video-player-box"
          ref="videoPlayer2"
          :options="playerOptions"
          :playsinline="true"
          customEventName="customstatechangedeventname"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
          @ended="onPlayerEnded($event)"
          @playing="onPlayerPlaying($event)"
          @statechanged="playerStateChanged($event)"
          @ready="playerReadied"
        >
        </video-player>
      </v-card>
    </v-dialog>

    <!-- <v-dialog
      max-width="500"
      v-model="modal.video"
      content-class="podcast__dialog"
    >
      <v-card style="background-color: transparent !important">
        <v-layout justify-center>
          <v-btn
            color="#466be3"
            class="mb-2 podcast__button"
            dark
            small
            @click="modal.video = false"
          >
            cerrar
          </v-btn>
        </v-layout>
        <video-player
          :height="220"
          :options="playerOptions"
          :playsinline="true"
          customEventName="customstatechangedeventname"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
          @ended="onPlayerEnded($event)"
          @playing="onPlayerPlaying($event)"
          @statechanged="playerStateChanged($event)"
          @ready="playerReadied"
        >
        </video-player>
      </v-card>
    </v-dialog> -->

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import { videoPlayer } from "vue-video-player";
import moment from "moment";
export default {
  components: {
    videoPlayer,
  },
  data() {
    return {
      isMobile: false,
      loadingAll: false,
      listPodcast: [],
      userInfo:{},
      token: "",
      infoToken: "",
      audioInfo: {
        url: "",
        img: "",
      },
      modal: {
        open: false,
        video: false,
      },
      playerOptions: {
        // videojs options
        muted: false,
        autoplay: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "application/x-mpegURL",
            src: "http://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8",
          },
        ],
        poster:
          "https://vhls.nyc3.digitaloceanspaces.com/okanwp/wp-content/uploads/2022/12/06154201/Recomiende-Okan-1-1.jpg",
      },
      token: "",
      infoToken: "",
      name: "",
      currentUser:[], 
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {
    "modal.open": function (n) {
      if (!n) {
        var con = document.getElementById("container-audio");
        var aud = document.getElementById("audio-e");
        con.removeChild(aud);
      }
    },
    "modal.video": function (n) {
      if (!n) {
        this.playerOptions.muted = true;
      }
    },
    user(newUser) {
      if (newUser) {
        this.currentUser = newUser;
        this.getAudios();
      }
    },

  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        //this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");
        var docList = this.infoToken.document;
        console.log(docList);

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getInfoMethod(){
      let usr
    },
    // listen event
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied(player) {
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },
    getAudios() {
      this.loadingAll = true;
      let cc; 
      let loginId; 
      cc = this.currentUser.document_number;
      loginId = this.currentUser.profile.id;
      // Llamada al método getInfoUser() para obtener la información del usuario
        Api.noAuth()
        .showAudios(cc,loginId)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.cod == 0) {
            this.listPodcast = data.data;
            for (let i = 0; i < this.listPodcast.length; i++) {
              this.listPodcast[i].showAudio = false;
              let date = moment(this.listPodcast[i].fecha).format("DD-MM-YYYY");
              this.listPodcast[i].fecha = date;
            }
            //console.log(this.listPodcast);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    open(data) {
      if (data["tipo-de-contenido"] == "false") {
        this.modal.open = true;
        this.audioInfo.url = data["enlace-video"];
        this.audioInfo.img = data.imagen;
        var con = document.getElementById("container-audio");
        setTimeout(() => {
          con.innerHTML =
            '<audio autoplay id="audio-e" ref="audio1" controls class="mt-1" style="width: 100%" > <source id="source-id" src="' +
            data["enlace-video"] +
            '" type="audio/mpeg" /> <object type="application/x-shockwave-flash" data="player.swf?miArchivo=mi-archivo-de-audio.mp3" > <param name="movie" value="player.swf?miArchivo=mi-archivo-de-audio.mp3" /> </object> </audio>';
        }, 100);
      } else {
        this.modal.video = true;
        this.playerOptions.muted = false;
        this.playerOptions.poster = data.imagen;
        this.playerOptions.sources[0].src = data["enlace-video"];
      }
    },

  },
  created() {
    this.getToken();
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  mounted() {
    this.currentUser = this.$store.getters.getUser;
    if (this.currentUser) {
      this.getAudios();
    }
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
/* .demo-content {
  z-index: 100 !important;
} */
.navidad-text{
  display: flex;
}
.mb-sm-podcast {
  display: none;
}

.video-js {
    width: 100% !important;
}

.txt-c {
  text-transform: capitalize;
}

.podcast__name-text {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}

.podcast__name-subtext {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.podcast__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.podcast__image {
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
}

.podcast__dialog {
  box-shadow: none !important;
  overflow-y: inherit !important;
}

.podcast__button:hover {
  background-color: #324ca1 !important;
}

/* LAPTOP 4k */

@media (max-width: 2000px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-podcast {
    display: none;
  }

  .mb-sm-podcast {
    display: block;
  }

  .podcast__container-img-mobile {
    width: 50%;
    margin: auto;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  /* .podcast__container-img-mobile {
    width: 50%;
  } */
}

@media (max-width: 425px) {
  .podcast__container-img-mobile {
    width: 80%;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
}

/* MOBILE S */
@media (max-width: 320px) {
}
</style>
